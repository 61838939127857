import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-accordion-title uppercase" }
const _hoisted_2 = { class: "inline-block text-icon-dark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_BaseCard = _resolveComponent("BaseCard")

  return (_openBlock(), _createBlock(_component_BaseCard, { class: "px-9 py-2 shadow-accordion transition-all ease-in-out duration-500" }, {
    default: _withCtx(() => [
      _createVNode("div", {
        class: "h-10 flex items-center justify-between cursor-pointer",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleDetails && _ctx.toggleDetails(...args)))
      }, [
        _createVNode("div", _hoisted_1, _toDisplayString(_ctx.$t(`faq-help.questions.${_ctx.question}.text`)), 1),
        _createVNode("div", _hoisted_2, [
          (_ctx.isDetailsVisible)
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 0,
                icon: ['fas', 'chevron-up']
              }))
            : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 1,
                icon: ['fas', 'chevron-down']
              }))
        ])
      ]),
      _withDirectives(_createVNode("div", {
        class: "pb-5 text-accordion-details font-light",
        innerHTML: _ctx.$t(`faq-help.questions.${_ctx.question}.answer`).replaceAll('\n', '<br/><br/>' )
      }, null, 8, ["innerHTML"]), [
        [_vShow, _ctx.isDetailsVisible]
      ])
    ]),
    _: 1
  }))
}