import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "m-auto w-11/12 md:w-4/6 pb-20" }
const _hoisted_2 = { class: "text-center mt-20 mb-9" }
const _hoisted_3 = { class: "text-24 md:text-30 lg:text-36 mb-5 font-bold" }
const _hoisted_4 = { class: "bg-body-dark h-28 flex flex-col lg:flex-row justify-center items-center rounded-md mb-7" }
const _hoisted_5 = { class: "text-center" }
const _hoisted_6 = { class: "mt-12 text-secondary-dark uppercase font-semibold" }
const _hoisted_7 = { class: "mt-2 text-14 text-secondary-dark font-light" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")
  const _component_BaseAccordion = _resolveComponent("BaseAccordion")
  const _component_DefaultLayout = _resolveComponent("DefaultLayout")

  return (_openBlock(), _createBlock(_component_DefaultLayout, null, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('faq-help.title')), 1)
        ]),
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_BaseInput, {
            modelValue: _ctx.searchString,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchString = $event)),
            name: "search-string",
            class: "mr-2 w-5/6 lg:w-3/6",
            "input-classes": "w-full h-12",
            icon: ['fas', 'search'],
            placeholder: _ctx.$t('faq-help.search-questions')
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        (_ctx.result.length > 0)
          ? (_openBlock(true), _createBlock(_Fragment, { key: 0 }, _renderList(_ctx.result, (question, index) => {
              return (_openBlock(), _createBlock(_component_BaseAccordion, {
                key: index,
                question: question.item.key,
                class: "mb-3"
              }, null, 8, ["question"]))
            }), 128))
          : (_openBlock(true), _createBlock(_Fragment, { key: 1 }, _renderList(_ctx.noFilterQuestions, (question, index) => {
              return (_openBlock(), _createBlock(_component_BaseAccordion, {
                key: index,
                question: question,
                class: "mb-3"
              }, null, 8, ["question"]))
            }), 128)),
        _createVNode("div", _hoisted_5, [
          _createVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('faq-help.cant-find')), 1),
          _createVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('faq-help.email-us')) + " " + _toDisplayString(_ctx.supportEmail), 1)
        ])
      ])
    ]),
    _: 1
  }))
}