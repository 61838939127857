
import {
  computed, ComputedRef, defineComponent, ref, inject,
} from 'vue';

import DefaultLayout from '@/components/layout/DefaultLayout.vue';
import BaseAccordion from '@/components/base/BaseAccordion.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import questions from '@/data/static/faq.json';
import { VueI18n } from 'vue-i18n';
import Fuse from 'fuse.js';

const TheFAQ = defineComponent({
  components: {
    BaseAccordion, BaseInput, DefaultLayout,
  },

  setup() {
    const i18n: VueI18n | undefined = inject('i18n');

    const faq = questions.map((q) => ({
      key: q,
      text: i18n && i18n.t(`faq-help.questions.${q}.text`),
    }));

    const fuse = new Fuse(faq, {
      includeScore: true,
      keys: ['text'],
    });

    const searchString = ref<string>('');

    const noFilterQuestions = computed(() => questions.splice(0, 7));

    const result = computed(() => fuse.search(searchString.value)
      .sort((a, b) => a.score! - b.score!).splice(0, 3));

    const supportEmail: ComputedRef<string | undefined> = computed(
      (): string | undefined => process.env.VUE_APP_SUPPORT_EMAIL,
    );

    return {
      supportEmail,
      searchString,
      result,
      noFilterQuestions,
    };
  },
});

export default TheFAQ;
