
import { defineComponent, ref } from 'vue';
import BaseCard from '@/components/base/BaseCard';

const BaseAccordion = defineComponent({
  components: { BaseCard },

  props: {
    question: {
      type: String,
      required: true,
    },
  },

  setup() {
    const isDetailsVisible = ref<boolean>(false);

    const toggleDetails = (): void => {
      isDetailsVisible.value = !isDetailsVisible.value;
    };

    return { isDetailsVisible, toggleDetails };
  },
});

export default BaseAccordion;
